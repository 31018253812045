import styled from "@emotion/styled";
import { creatorEditing, normalizeState } from "@relatable/helpers/approvalLogs";
import { CONTENT_APPROVAL_STATE } from "@relatable/helpers/constants";
import { palette } from "@relatable/ui/Palette";

import { CONTENT_APPROVAL_STATE_CREATOR_LABELS } from "../utils";
import { Header } from "./Header";
import { StepContainer } from "./Step/StepContainer";
import type { CONTENT_APPROVAL_STEP } from "./StepperContainer";

const Title = styled.h2<{ variant: "success" | "warning" }>`
  margin-bottom: 0;
  padding-top: 20px;
  text-align: center;
  color: ${p => (p.variant === "success" ? palette.primary.green : palette.secondary.gold)};
`;

export const Stepper: React.FC<{
  activeStep: CONTENT_APPROVAL_STEP;
  contentState: CONTENT_APPROVAL_STATE;
  onNextStep: () => void;
  nextStep: CONTENT_APPROVAL_STEP | null;
  onPrevStep: () => void;
  canProceed: boolean;
  loadingAddComment: boolean;
}> = ({
  contentState,
  onNextStep,
  nextStep,
  onPrevStep,
  activeStep,
  canProceed,
  loadingAddComment
}) => {
  const slideDirection = activeStep === "upload" ? "right" : "left";

  return (
    <>
      <Header
        activeStep={activeStep}
        contentState={contentState}
        onNextStep={onNextStep}
        onPrevStep={onPrevStep}
        canProceed={canProceed}
        nextStep={nextStep}
      />

      <Title
        variant={
          normalizeState(contentState) === CONTENT_APPROVAL_STATE.READY_FOR_PUBLISHING
            ? "success"
            : "warning"
        }
      >
        Status: {CONTENT_APPROVAL_STATE_CREATOR_LABELS[normalizeState(contentState)] ?? "-"}
      </Title>

      <div style={{ padding: "0 20px" }}>
        {creatorEditing(contentState) && (
          <p style={{ fontWeight: "bold" }}>Remember to submit your content before you leave!</p>
        )}
      </div>

      <div style={{ overflow: "hidden", width: "100%" }}>
        <Root>
          <StepContainer
            loadingAddComment={loadingAddComment}
            contentState={contentState}
            activeStep={activeStep}
          />
        </Root>
      </div>
    </>
  );
};

const Root = styled.div`
  background-color: ${palette.gray.white};
  width: 100%;
  margin-top: 40px;
  margin-bottom: 39px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const TransitionContainer = styled.div<{ $slideDirection: "right" | "left" }>`
  .slide-enter {
    opacity: 0;
    transform: ${({ $slideDirection }) =>
      $slideDirection === "left" ? "translateX(400px)" : "translateX(-400px)"};
  }
  .slide-enter-active {
    opacity: 1;
    transform: translateX(0%);
    transition:
      transform 350ms ease-in-out,
      opacity 350ms ease-out;
  }
  .slide-exit {
    display: none;
  }
  .slide-exit-active {
    display: none;
  }
`;
